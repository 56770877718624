import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

type AlertDismissibleProp = {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>
}
function AlertDismissible(props: AlertDismissibleProp) {

  if (props.show) {
    return (
      <Alert variant="success" onClose={() => props.setShow(false)} dismissible>
        <Alert.Heading>¡Tu mensaje ha sido enviado exitosamente!</Alert.Heading>
        <p>
            Estaremos en contacto con usted.
        </p>
      </Alert>
    );
  }
else{
    return null
}
//   return <Button onClick={() => props.setShow(true)}>Show Alert</Button>;
}

export default AlertDismissible