import React from "react";
import ContactUs from "../components/contact-us-form";
import Footer from "../components/footer";
import Header from "../components/navbar";

const ContactUsPage = () => {
  return (
    <div className="flex-auto flex-col bg-logoColor">
      <header className="sticky top-0 z-50">{<Header />}</header>

      <div className="bg-horseField bg-cover ">
        <div className="bg-black bg-opacity-75 min-h-[70vh] ">
          <div className="flex flex-col p-10">

          <div className="self-center">
            <p className="text-gray-50 text-3xl">¡Contactanos!</p>
          </div>
          <div className="m-10">
            <ContactUs />
          </div>
          </div>
        </div>
      </div>
      {<Footer />}
    </div>
  );
};
export default ContactUsPage;
