import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm, useController, SubmitHandler, set } from "react-hook-form";
import InputGroup from "react-bootstrap/InputGroup";
import AlertDismissible from "./utilities/alert";
interface IFormInput {
  email: string;
  message: string;
  name: string;
  phone: string;
}
function ContactUs() {
  const { control, handleSubmit, formState, reset } = useForm<IFormInput>();
  const { field: emailField } = useController({
    name: "email",
    control: control,
    defaultValue: "",
    rules: {
      required: true,
      validate: {
        emailFormat: (email) => isValidEmail(email),
      },
    },
  });
  const { field: messageField } = useController({
    name: "message",
    defaultValue: "",
    control: control,
    rules: { required: true },
  });
  const { field: nameField } = useController({
    name: "name",
    control: control,
    defaultValue: "",
  });
  const { field: phoneField } = useController({
    name: "phone",
    control: control,
    defaultValue: "",
  });
  const encode = (data: IFormInput & { "form-name": string }) => {
    const dataKeys: (keyof IFormInput)[] = Object.keys(
      data
    ) as unknown as (keyof IFormInput)[];
    return dataKeys
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const [show, setShow] = useState(false);

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const dataEncoded = encode({ "form-name": "contact", ...data });
    console.log(dataEncoded);
    // fetch("flowers.jpg")
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: dataEncoded,
      })
      .then(() => {
        console.log("Form successfully submitted");
        setIsSubmitSuccessful(true);
      })
      .catch((error) => alert(error));
  };
  const isValidEmail = (email: string) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  useEffect(() => {
    reset();
    setShow(isSubmitSuccessful);
  }, [isSubmitSuccessful]);

  useEffect(() => {
    setIsSubmitSuccessful(show);
  }, [show]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="contact" />
      <InputGroup>
        <div className="flex flex-row gap-2 my-3">
          <Form.Control
            {...nameField}
            placeholder="Nombre"
            aria-label="Nombre"
            aria-describedby="basic-addon2"
          />
          <Form.Control
            {...phoneField}
            placeholder="Teléfono"
            aria-label="Teléfono"
            aria-describedby="basic-addon2"
          />
        </div>
      </InputGroup>
      <InputGroup hasValidation={true} className="mb-3">
        <Form.Control
          {...emailField}
          isInvalid={formState.errors.email ? true : false}
          placeholder="Correo (requerido)"
          aria-label="Correo"
          aria-describedby="basic-addon2"
        />
        <Form.Control.Feedback type="invalid">
          La dirreción de correo debe de ser válida.
        </Form.Control.Feedback>
      </InputGroup>
      <Form.Control
        {...messageField}
        isInvalid={formState.errors.message ? true : false}
        as="textarea"
        aria-label="Mensaje"
        placeholder="Mensaje (requerido)"
      />
      <Form.Control.Feedback type="invalid">
        El cuerpo del mensaje es requerido.
      </Form.Control.Feedback>
      <Button className="mt-3" variant="primary" type="submit">
        Enviar
      </Button>
      <AlertDismissible show={show} setShow={setShow} />
    </form>
  );
}
export default ContactUs;
